<template>
  <div class="dependents-list-cards">
    <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
    <div v-for="item in renderItems" :key="item.id" class="data-table dependents-list-card">
      <div class="main-details">
        <Avatar :size="40" :src="item.photoURL" />
        <div>
          <div class="title">{{ item.firstName }} {{ item.lastName }}</div>
          <div class="description pointer d-flex align-center">
            {{ item.medicalRecordNumber }}
            <img
              :src="require('@/assets/icons/mrn.svg')"
              alt="medicalRecordNumber"
              class="ml-2"
              title="Medical record number"
            />
          </div>
          <template v-if="!isChildren">
            <div v-if="isInviteActive(item)" class="invitation-info active">
              <v-icon> mdi-circle-small</v-icon>
              Active
            </div>
            <div v-if="isInviteRequired(item)" class="invitation-info required">
              <v-icon> mdi-circle-small</v-icon>
              Invitation required
            </div>
            <div v-if="isInviteSent(item)" class="invitation-info sent">
              <v-icon> mdi-circle-small</v-icon>
              Invitation sent
            </div>
          </template>
        </div>
      </div>
      <div class="contact-details">
        <ul>
          <li>
            <img :src="require('@/assets/icons/profile.svg')" alt="Birth Date" />
            {{ birthDate(item.birthDate) }}
          </li>
          <li>
            <img :src="require('@/assets/icons/location.svg')" alt="city" />
            {{ item.city || "--" }}
          </li>
          <li>
            <img :src="require('@/assets/icons/mail.svg')" alt="email" />
            <a v-if="item.email" :href="`mailto:${item.email}`">{{ item.email || "--" }}</a>
            <template v-else>--</template>
          </li>
          <li>
            <img :src="require('@/assets/icons/male_alt.svg')" alt="male_alt" />
            {{ item.practitioner || "--" }}
          </li>
          <li>
            <img :src="require('@/assets/icons/phone.svg')" alt="homePhoneNumber" />
            <a v-if="item.homePhoneNumber" :href="`tel:${item.homePhoneNumber}`">{{ item.homePhoneNumber || "--" }}</a>
            <template v-else>--</template>
          </li>
          <li>
            <img :src="require('@/assets/icons/mobile_alt.svg')" alt="phoneNumber" />
            <a v-if="item.phoneNumber" :href="`tel:${item.phoneNumber}`">{{ item.phoneNumber }}</a>
            <template v-else>--</template>
          </li>
        </ul>
      </div>
      <div class="contact-actions">
        <OutlinedButton
          v-if="!isChildren && isInviteRequired(item)"
          additionalClassName="outlined-btn__green"
          text="Send invite"
          @onClick="$emit('onRequestInvite', item)"
        />
        <OutlinedButton
          v-if="!isChildren && isInviteSent(item)"
          additionalClassName="outlined-btn__yellow"
          text="Re-send invite"
          @onClick="$emit('onRequestInvite', item)"
        />
        <PrimaryButton
          v-if="isChildren && acceptPediatricVisits"
          class="primary-btn__blue request-consult-btn"
          text="Request a Consult"
          @onClick="onRequestConsultClick(item)"
        />
      </div>
    </div>
    <div class="patients-cards-footer mt-8 row align-center justify-space-between">
      <div class="d-flex align-center col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <p class="mb-0">
          <span class="sub-heading-3">Showing </span>
          <span class="primary--text font-weight-bold">{{ perPage > items.length ? items.length : perPage }}</span>
          <span class="sub-heading-3"> of </span>
          <span class="font-weight-bold">{{ items.length }}</span>
        </p>
        <v-divider class="patients-cards-footer__middle-divider mx-5" vertical></v-divider>
        <div class="d-flex align-center">
          <p class="sub-heading-3 mb-0 mr-2">Rows per Page:</p>
          <v-select
            :items="itemsPerPageItems"
            :value="perPage"
            append-icon="mdi-chevron-down"
            class="patients-cards-footer__per-page-select"
            dense
            hide-details
            outlined
            @change="onItemsPerPageChange"
          ></v-select>
        </div>
      </div>
      <v-pagination
        v-model="page"
        :length="itemsLength"
        :total-visible="$vuetify.breakpoint.smAndDown ? 5 : 7"
        class="patients-cards-pagination col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";

import Avatar from "@/components/Avatar/Index";
import { timeDisplayFormat } from "@/components/Clinicians/constants";
import OutlinedButton from "@/components/uikit/OutlinedButton.vue";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import { useAuthStore } from "@/pinia-store/auth";
import { useMeetingRoomStore } from "@/pinia-store/meetingRoom";
import { paginate } from "@/pinia-store/slots";
import { useVisitSettingsStore } from "@/pinia-store/visitSettings";
import { RolesEnum } from "@/types/Roles.enum";
import { templateString } from "@/utils/locales.helpers";

export default {
  name: "DependentsListCards",
  components: { OutlinedButton, PrimaryButton, Avatar },
  props: {
    headers: Array,
    items: Array,
    search: String,
    loading: Boolean,
    actions: Array,
    showRequestConsult: {
      default: true,
      type: Boolean,
    },
    withInvite: {
      default: false,
      type: Boolean,
    },
    isChildren: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      perPage: 10,
      page: 1,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["displayName", "userIsPatient", "role"]),
    ...mapState(useMeetingRoomStore, ["template", "inviteDialog"]),
    ...mapState(useVisitSettingsStore, ["acceptPediatricVisits"]),
    pagination() {
      return {
        itemsPerPage: this.itemsPerPageItems,
        perPage: this.perPage,
        page: this.page,
      };
    },

    itemsLength() {
      return Math.ceil(this.items.length / this.perPage);
    },
    renderItems() {
      return paginate(this.items || [], this.perPage, this.page);
    },
    itemsPerPageItems() {
      return [
        {
          text: 5,
          value: 5,
        },
        {
          text: 10,
          value: 10,
        },
        {
          text: 15,
          value: 15,
        },
        {
          text: "All",
          value: this.items.length,
        },
      ];
    },
  },
  methods: {
    ...mapActions(useMeetingRoomStore, [
      "toggleInviteDialog",
      "cleanUpInvite",
      "setInviteText",
      "setInviteEmail",
      "setInviteReceiver",
      "setInviteMethod",
      "setInviteFullName",
      "setInviteId",
      "setInvitePhoneNumber",
      "setParticipantOptions",
      "toggleInviteDialog",
    ]),
    isInviteActive(item) {
      return Boolean(item?.invite?.useDate);
    },
    isInviteRequired(item) {
      return !item?.invite?.id;
    },
    isInviteSent(item) {
      return Boolean(item?.invite?.id && !item?.invite?.useDate);
    },
    onRequestConsultClick(item) {
      this.$emit("openRequestConsultDialog", item);
    },
    async redirectToMedicalRecord(item) {
      if (this.role !== RolesEnum.Patient) await this.$router.push(`/practitioner/record/patient/${item.id}`);
    },
    async inviteToRoom(recipient, method) {
      this.setInviteMethod(method);
      this.setInviteFullName(`${recipient.fullName || `${recipient.firstName || ""} ${recipient.lastName || ""}`}`);
      this.setInvitePhoneNumber(recipient.phoneNumber || "");
      this.setInviteEmail(recipient.email || "");
      this.setInviteId(recipient.id || "");

      const inviteText = templateString(this.template, {
        id: recipient.id,
        name: recipient.fullName || "",
        practitioner: this.displayName || "",
        link: "%link%",
      });
      this.setInviteText(inviteText);
      this.toggleInviteDialog();
    },
    birthDate(value) {
      return this.$moment(value, "MM/DD/YYYY").format(timeDisplayFormat);
    },
    practitioner(item) {
      return item.practitioner ? `${item?.practitioner?.firstName || ""} ${item?.practitioner?.lastName || ""}` : "";
    },
    onItemsPerPageChange(perPage) {
      this.perPage = perPage;
      if (this.page > this.itemsLength) {
        this.page = this.itemsLength;
      }
    },
  },
};
</script>

<style lang="scss">
.dependents-list-cards {
  .invitation-info {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;

    i {
      color: inherit;
    }

    &.active {
      color: #2196f3;
    }

    &.required {
      color: #91909c;
    }

    &.sent {
      color: #f2994a;
    }
  }

  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;

  .request-consult-btn {
    max-width: 180px;
  }

  .dependents-list-card {
    background: #ffffff;
    border: 1px solid #e7e8f2;
    border-radius: 3px;
    display: flex;
    padding: 20px;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    @include tablet {
      flex-direction: column;
    }

    .main-details {
      display: flex;
      flex-basis: 230px;
      gap: 14px;
      align-items: center;
      flex-shrink: 0;

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #33343e;
      }

      .description {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #33343e;
        opacity: 0.4;
      }

      @include tablet {
        flex-basis: 100%;
      }
    }

    .contact-details {
      align-items: center;
      display: flex;
      flex-basis: 100%;
      max-width: 710px;
      @include tablet {
        flex-basis: 100%;
        max-width: 100%;
      }

      ul {
        padding-left: 0;
        column-count: 3;
        width: 100%;
        @include extraLargeUp {
          column-count: 3;
        }
        @include extraLarge {
          column-count: 2;
        }
        @include large {
          column-count: 2;
        }
        @include desktop {
          column-count: 2;
        }
        @include tablet {
          column-count: 2;
        }
        @include mobile {
          column-count: 1;
        }

        li {
          list-style: none;
          display: flex;
          align-items: center;
          color: #33343e;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          max-width: 210px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis ellipsis;

          a {
            text-decoration: none;
            color: #33343e;
          }

          img {
            width: 16px;
            margin-right: 8px;
          }
        }
      }
    }

    .contact-actions {
      flex-basis: 260px;
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
      justify-content: flex-end;
      flex-direction: column;

      > button {
        flex: 1 auto;
        @media screen and (min-width: $mobile-width) and (max-width: $desktop-width) {
          &:not(.request-consult-btn) {
            max-width: 118px;
          }
        }
      }

      @include largeUp {
        flex-basis: auto;
        flex-direction: row;
      }
      @include large {
        flex-basis: 260px;
        flex-direction: row;
      }
      @include desktop {
        flex-basis: 100%;
        flex-direction: row;
      }
      @include tablet {
        flex-basis: 100%;
        flex-direction: row;
        width: 100%;
      }
      @include mobile {
        flex-basis: 100%;
        width: 100%;
        flex-direction: row;
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

.patients-cards-footer {
  &__per-page-select {
    width: 100px;
    border: 2px solid $primarylight;
    border-radius: 4px;

    fieldset {
      border: none;
    }
  }
}

.patients-cards-pagination {
  ul {
    justify-content: flex-end;
    @media screen and (max-width: 960px) {
      justify-content: flex-start;
    }

    li {
      button {
        box-shadow: none;
        border: 2px solid $primarylight;
        box-sizing: border-box;
        border-radius: 4px;

        &.v-pagination__navigation {
          box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);
        }

        &.v-pagination__item {
          font-weight: bold;
          color: $primarylight3 !important;
        }

        &.v-pagination__item--active {
          color: #ffffff !important;
        }
      }
    }
  }
}
</style>
